
import { Component, Vue } from 'vue-property-decorator';
import { mdiChevronRight } from '@mdi/js';
import { FeatureModule, UserModule } from '@/store/modules';

import { IAuthUser } from '@/types/user.types';

import UserAvatar from '@/components/UserAvatar.vue';

@Component({
  components: {
    UserAvatar
  }
})
export default class Settings extends Vue {
  icons = {
    arrow: mdiChevronRight
  };
  get user(): IAuthUser | null {
    return UserModule.user;
  }
  links = [
    { path: '/settings/change-password', text: 'Change Password' },
    { path: '/settings/enroll-card', text: 'Manage Cards' },
    { path: '/settings/notifications', text: 'Notifications' },
    { path: '/settings/referrals', text: 'Referral Program' }
  ];

  async created(): Promise<void> {
    const { isRoundUpEnabled, isMultipleCauseEnabled } = FeatureModule.getFeatureStatuses;
    if (isMultipleCauseEnabled) {
      this.links.splice(4, 0, {
        path: '/settings/change-cause',
        text: 'Manage Charity'
      });
    }

    if (isRoundUpEnabled) {
      this.links.splice(3, 0, {
        path: '/settings/round-up-program',
        text: 'Round UP Program'
      });
    }
  }
}
